// import * as ga from '@/lib/ga'
// import * as fpixel from '@/lib/fpixel'
import * as gtm from '@/lib/gtm'
import { ArticleModel, CartModel, OrderConfirmationInfoModel, OrderCreatedModel } from '@/types/api'
import { useLazyGetOrderQuery } from '@/api/order'
import { useAppSelector } from './useAppSelector'
import { selectCurrency } from '@/features/app/appSlice'
import { useRef } from 'react'

const useAnalyticsManager = () => {
  const [getOrder, orderResult] = useLazyGetOrderQuery()
  const selectedCurrency = useAppSelector(selectCurrency)
  const loadingOrder = useRef<boolean>(false)

  const pageView = (url: string) => {
    gtm.pageView(url)
  }

  const addToCart = (articleId: number, articleText: string, quantity: number, price: number) => {
    gtm.addToCart(articleId, articleText, quantity, price, selectedCurrency)
  }

  const search = (searchTerm: string) => {
    gtm.search(searchTerm)
  }

  const viewProduct = (article: ArticleModel) => {
    gtm.viewItem(article.id, article.articleText, article.price, selectedCurrency)
  }

  const viewProductList = (articles: ArticleModel[], listId: string, listName: string) => {
    gtm.viewItemList(listId, listName, articles.map(article => {
      return {
        item_id: article.id,
        item_name: article.articleText,
        price: article.price
      }
    }))
  }

  const clickProduct = (article: ArticleModel, listId: string, listName: string) => {
    gtm.selectItem(listId, listName, {
      item_id: article.id,
      item_name: article.articleText,
      price: article.price
    })
  }

  const viewCart = (cart: CartModel) => {
    gtm.viewCart(cart.totalAmount, selectedCurrency, cart.rows.map(row => {
      return {
        item_id: row.articleId,
        item_name: row.articleText,
        quantity: row.quantity,
        price: row.price
      }
    }))
  }

  const initiateCheckout = (cart: CartModel) => {
    const items = cart.rows.map(row => {
      return {
        item_id: row.articleId,
        item_name: row.articleText,
        quantity: row.quantity,
        price: row.price
      }
    })

    gtm.beginCheckout(cart.totalAmount, selectedCurrency, items)
  }

  const addPaymentInfo = (paymentMethod: string, cart: CartModel) => {
    gtm.addPaymentInfo(paymentMethod, cart.totalAmount, selectedCurrency, cart.rows.map(row => {
      return {
        item_id: row.articleId,
        item_name: row.articleText,
        quantity: row.quantity,
        price: row.price
      }
    }))
  }

  const changeDeliveryAddress = () => {

  }

  const purchase = async (orderId: number) => {
    // Prenvent double tracking
    if (orderResult.data?.id === orderId || orderResult.isLoading || loadingOrder.current) {
      return
    }

    loadingOrder.current = true
    const order = await getOrder(orderId)
    loadingOrder.current = false

    if (!order.data) {
      return
    }

    gtm.purchase(order.data.totalAmount, order.data.currencyCode, order.data.rows.map(row => {
      return {
        item_id: row.articleId,
        item_name: row.description,
        quantity: row.quantity,
        price: row.price
      }
    }))
  }

  const login = (userId: string) => {
    gtm.login(userId)
  }

  const register = (userId: string) => {
    gtm.signUp(userId)
  }

  return {
    pageView,
    addPaymentInfo,
    addToCart,
    search,
    viewProduct,
    viewProductList,
    clickProduct,
    viewCart,
    initiateCheckout,
    purchase,
    changeDeliveryAddress,
    login,
    register
  }
}

export default useAnalyticsManager
/* eslint-disable @next/next/no-img-element */
import { DeviceType, useDeviceType } from '@/utils/hooks/useDeviceType'
import Head from 'next/head'
import React, { useEffect } from 'react'
import FloatingBar from '../FlaotingBar/FloatingBar'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import MobileHeader from '../MobileHeader/MobileHeader'
import TopSiteBar from '../TopSiteBar/TopSiteBar'
import styles from './BaseLayout.module.scss'
import { GTM_ID } from '@/lib/gtm'
import { GoogleTagManager } from '@next/third-parties/google'
import useAnalyticsManager from '@/utils/hooks/useAnalyticsManager'

type Props = {
  children: React.ReactNode
}

const BaseLayout = ({ children }: Props) => {
  const device = useDeviceType()
  const analyticsManager = useAnalyticsManager()

  useEffect(() => {
    analyticsManager.pageView(window.location.pathname)
  }, [analyticsManager])

  return (
    <>
      <Head>
        <title>Statusfälgar</title>
        <meta name="description" content="Din ultimata destination för fälgar, däck och kompletta hjul" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="google-site-verification" content="zug4UxVpwo4LAc9IitroKRo17QPSSNlQMTiMB-HOe8s" />
        <meta name="google-site-verification" content="oOlJaCHn0ce6YKX737ICedveAaeszIvKhkfpuH5eu14" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <GoogleTagManager
        gtmId={GTM_ID || ''}
        // gtmScriptUrl={`https://ssi.statusfalgar.se/gtm.js`}
      />
      {device === DeviceType.Desktop && (
        <TopSiteBar />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          {device === DeviceType.Mobile && (
            <MobileHeader />
          )}
          {device === DeviceType.Desktop && (
            <Header />
          )}
          {children}
          <Footer />
          <FloatingBar />
        </div>
      </div>
    </>
  )
}

export default BaseLayout
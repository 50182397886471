import styles from './Button.module.scss'
import classNames from 'classnames'
import React, { ReactElement } from 'react'
import ArrowIcon from '@/assets/svg/arrow-right.svg'

type Props = {
  onClick?: () => void
  label: string,
  type?: 'button' | 'submit' | 'reset',
  rounded?: boolean,
  dark?: boolean,
  darkGradient?: boolean,
  lightDark?: boolean,
  active?: boolean,
  arrow?: boolean,
  medium?: boolean,
  large?: boolean,
  icon?: React.FunctionComponent<any>,
  iconPosition?: 'left' | 'right',
  width?: string,
  className?: string,
  disabled?: boolean,
}

const Button = ({
  onClick,
  label,
  type = 'button',
  rounded = false,
  dark = false,
  darkGradient,
  lightDark = false,
  active = false,
  arrow = false,
  medium = false,
  large = false,
  icon = undefined,
  iconPosition = 'left',
  width = undefined,
  className,
  disabled
}: Props) => {

  const onClickHandler = () => {
    if (onClick === undefined) return

    onClick()
  }

  const classes = classNames(
    styles.button,
    className,
    {
      [styles.rounded]: rounded,
      [styles.dark]: dark,
      [styles.darkGradient]: darkGradient,
      [styles.active]: active,
      [styles.medium]: medium,
      [styles.large]: large,
      [styles.lightDark]: lightDark,
      [styles.iconRight]: icon && iconPosition === 'right',
    })

  const IconComponent = icon as React.ElementType

  const style = width ? { width: width } : {}

  return (
    <button
      className={classes}
      onClick={onClickHandler}
      style={style}
      type={type}
      disabled={disabled}
    >
      {icon && (
        <IconComponent
          className={styles.icon}
        />
      )}
      {label}
      {arrow && (
        <ArrowIcon
          className={styles.arrow}
        />
      )}
    </button>
  )
}

export default Button
